/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// * {
// 	-webkit-font-smoothing: antialiased
// }
// COLORS THEMES

// DANGER
:root {
	--ion-color-danger: #c62828;
	--ion-color-danger-rgb: 198,40,40;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ae2323;
	--ion-color-danger-tint: #cc3e3e;
}

.ion-color-danger {
	--ion-color-base: var(--ion-color-danger);
	--ion-color-base-rgb: var(--ion-color-danger-rgb);
	--ion-color-contrast: var(--ion-color-danger-contrast);
	--ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
	--ion-color-shade: var(--ion-color-danger-shade);
	--ion-color-tint: var(--ion-color-danger-tint);
}

// PRIMARY
:root {
	--ion-color-primary: #080b2d;
	--ion-color-primary-rgb: 8,11,45;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #070a28;
	--ion-color-primary-tint: #212342;
	--ion-card-background: #080b2d !important;	
	--ion-card-primary: #a001da;
	--border-color: #f0ebee;
	--code-background-color: #0c2e4e;
	--ion-background-color: #ffffff !important;
}

.ion-color-primary {
	--ion-color-base: var(--ion-color-primary);
	--ion-color-base-rgb: var(--ion-color-primary-rgb);
	--ion-color-contrast: var(--ion-color-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary-tint);
}

// DARK MODE 
body.dark {
	--ion-color-primary: #43b6d6;
	--ion-color-primary-rgb: 67,182,214;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #3ba0bc;
	--ion-color-primary-tint: #56bdda;
	--ion-background-color: #1f2123 !important;
	--ion-card-background: #34373a !important;
	--border-color: #262626;
	--code-background-color: #1e1e1e;
	.input {
		&:focus {
			box-shadow: 0px 0px 0px 3px rgba(#43b6d6, .3) !important;
		}
	}
	.light-text {
		color: #737373 !important;
	}
	.box-shadow {
		box-shadow: inset 0 0px 0px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(0 0 0 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important;
	}
}
@media (prefers-color-scheme: dark) {
	.input {
		&:focus {
			box-shadow: 0px 0px 0px 3px rgba(#43b6d6, .3) !important;
		}
	}
	.light-text {
		color: #737373 !important;
	}
	.box-shadow {
		box-shadow: inset 0 0px 0px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(0 0 0 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important;
	}
    :root {
		--ion-color-primary: #43b6d6;
		--ion-color-primary-rgb: 67,182,214;
		--ion-color-primary-contrast: #000000;
		--ion-color-primary-contrast-rgb: 0,0,0;
		--ion-color-primary-shade: #3ba0bc;
		--ion-color-primary-tint: #56bdda;
		--ion-background-color: #1f2123 !important;
		--ion-card-background: #34373a !important;
		--border-color: #262626;
		--code-background-color: #1e1e1e;
	}
	body {
		--ion-color-primary: #43b6d6;
		--ion-color-primary-rgb: 67,182,214;
		--ion-color-primary-contrast: #000000;
		--ion-color-primary-contrast-rgb: 0,0,0;
		--ion-color-primary-shade: #3ba0bc;
		--ion-color-primary-tint: #56bdda;
		--ion-background-color: #1f2123 !important;
		--ion-card-background: #34373a !important;
		--border-color: #262626;
		--code-background-color: #1e1e1e;
	}
}

// * {
// 	-ms-word-break: break-all;
// 	word-break: break-all;

// 	/* Non standard for WebKit */
// 	word-break: break-word;

// 	-webkit-hyphens: auto;
// 	-moz-hyphens: auto;
// 		hyphens: auto;
// }
$border-color: var(--border-color);

// IONIC CLASSES OVERWRITE
ion-title {
	font-weight: 400;
	color: #505f73;
	cursor: pointer;
}
ion-button {
	--box-shadow: none !important;
	--ripple-color: transparent;
	margin: 0 !important
}

// GENERAL

.hide-scrollbar::-webkit-scrollbar,
.hide-scrollbar-overlay::-webkit-scrollbar {
    display: none !important;
}
/* For Firefox and IE */
.hide-scrollbar {
    height: 100% !important;
    overflow-y: scroll !important;
    scrollbar-width: none !important;
	-ms-overflow-style: none !important;
}
main {
	section,
	footer {
	  position: relative;
	  z-index: 1;
	  .section-overlay {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		.section-overlay-wrapper {
		  position: relative;
		  overflow: hidden;
		}
	  }
	  .section-container {
		margin: 0 auto;
		width: 100%;
		max-width: 1200px;
		.section-container-wrapper {
		  z-index: 1;
		  padding: 20px;
		  @media (min-width: 1200px) {
			border-left: 1px dashed rgba(#505f73, .15);
			border-right: 1px dashed rgba(#505f73, .15);
		  }
		}
	  }
	}
  }
.prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
.no-pointer-events {
	pointer-events: none;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.container-padding {
	padding: 20px;
}
.container-border {
	border: 1px solid $border-color;
	border-radius: 6px;
}
.container-background {
	background-color: var(--ion-background-color);
}
.border-large {
	border-radius: 18px;
}
.chip-button {
	--border-radius: 18px !important;
	--font-weight: 600 !important;
}
.pointer {
	&:hover {
		cursor: pointer
	}
}
.box-shadow {
	box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}
.input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
}
.input {
    padding: 8px 12px;
    border: 1px solid $border-color;
    border-radius: 6px;
    background-color: var(--ion-background-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    color: var(--ion-text-color);
    margin-bottom: 20px;
    &:focus {
        outline-offset: 0px !important;
        outline: none !important;
        border-color: var(--ion-color-primary) !important;
        box-shadow: 0px 0px 0px 3px rgba(#080b2d, .3);
    }
}
.input-danger, 
.input-danger:focus,
.container-focus-danger {
    border-color: var(--ion-color-danger) !important;
    box-shadow: 0px 0px 0px 3px rgba(#c62828, .3) !important;
}
textarea {
	resize: none;
	min-height: 100px;
}
// TEXT CLASSES
:root {
    --ion-text-color: #080b2d; //#262626;
}

body {
    --ion-text-color: #080b2d; //#262626;
}

.info {
	bottom: 0px;
	right: 0px;
	padding: 15px;
	font-size: 12px;
	margin: 0;
	text-align: center;
	color: #8c8c8c;
}
  
.info a  {
	text-decoration: none;
	color: var(--ion-color-primary);
	&:hover {
		text-decoration: underline
	}
}

.large-text {
	font-weight: 300;
	font-size: 6em
}
// .primary-text {
// 	color: var(--ion-color-primary) !important
// }
.primary-text {
	color: var(--ion-color-primary) !important
}
.card-primary-text {
	color: var(--ion-card-primary) !important
}
.text-color {
    color: var(--ion-text-color) !important;
}
.lighter-text {
	color: #fff;
}
.light-text {
	color: #505f73;
}
.font-10 {
	font-size: 10px !important;
}
.font-12 {
	font-size: 12px !important;
}
.font-14 {
	font-size: 14px !important;
}
.font-15 {
	font-size: 15px !important;
}
.font-16 {
	font-size: 16px !important;
}
.font-18 {
	font-size: 18px !important;
}
.font-20 {
	font-size: 20px !important;
}
.font-24 {
	font-size: 24px !important;
} 
.italic {
	font-style: italic
}
footer {
	background-color: var(--ion-card-background);
	color: #f5f5f5
}
.footer-text {
	font-size: 13px;
	a {
		text-decoration: none;
		color: #f5f5f5;
		cursor: pointer;
	}
}
// FLEX CLASSES
.flex-row {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.flex-col {
	display: flex;
	flex-direction: column;
}
.flex-wrap {
	flex-wrap: wrap;
}
.width-100 {
	width: 100%
}
.height-100 {
	height: 100%
}
.flex-center-horizontal {
	justify-content: center
}
.flex-center-vertical {
	align-items: center
}
.flex-center-center {
	justify-content: center;
	align-items: center
}
.flex-start {
	justify-content: start;
	align-items: start;
}
.gap-5 {
	gap: 5px
}
.gap-10 {
	gap: 10px
}
.gap-15 {
	gap: 15px
}
.gap-20 {
	gap: 20px
}
.gap-30 {
	gap: 30px
}
.gap-40 {
	gap: 40px
}
.gap-50 {
	gap: 50px
}
.gap-100 {
	gap: 100px
}
.spacer {
	flex: 12
}
.no-margin  {
	margin: 0 !important;
}
.margin-bottom-10 {
	margin-bottom: 10px;
}
.margin-bottom-20 {
	margin-bottom: 20px;
}
.margin-top-100 {
	margin-top: 100px;
}
.margin-top-200 {
	margin-top: 200px;
}
.margin-bottom-100 {
	margin-bottom: 100px;
}
.margin-bottom-200 {
	margin-bottom: 200px;
}
.padding-50 {
	padding: 50px;
}
.padding-top-100 {
	padding-top: 100px;
}
.padding-top-200 {
	padding-top: 200px;
}
.padding-bottom-100 {
	padding-bottom: 100px;
}
.padding-bottom-200 {
	padding-bottom: 200px;
}
// IMAGE CLASSES

.logo-small {
	height: 32px;
	width: 32px;
    object-fit: contain;
}
// GRID CLASSES 

.grid-col-2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
    gap: 20px;
    align-content: center;
}
.grid-col-3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 20px;
    align-content: center;
}
.grid-col-4 {
	width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    gap: 20px;
    align-content: center;
}

@media (max-width: 992px) {
    .grid-col-2 {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .grid-col-3 {
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    }
    .grid-col-4 {
         grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
	}
	.footer-text {
		font-size: 11px;
	}
}
@media (max-width: 767.98px) {
	.footer-text {
		font-size: 11px;
	}
}
